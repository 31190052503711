import React from 'react'
import { FaUserCircle } from 'react-icons/fa'
/* import { BsStarFill } from 'react-icons/bs' */
import Starts from './Starts'


function CardReview({ title }) {

    const rpdataReviews = [
        {
            nombreR: 'Jerry Criz',
            coment: 'I am happy to recommend Vasa Construction LLC to do the tile work on your home. They did a great job for us and we would use them again on future projects. They were very cooperative with all aspect of the job. The presented a very positive attitude at all times. They were on the job every day until it was completed. They cleaned up after themselves each day.',
            stars: 5,
            directorio: 'manta'

        },
        {
            nombreR: 'Johny Thomas',
            coment: 'Vasa Construction LLC did a wonderful remodeling job on my kitchen! They were able to utilize every inch of room space, just as I wanted and not a day has gone by that I don`t smile whenever I`m in my kitchen!',
            stars: 5,
            directorio: 'manta'

        },
        {
            nombreR: 'Kim Le',
            coment: 'Vasa Construction LLC performed miracles on our old kitchen. The place looks incredible now- modern and comfortable. It is unbelievable, considering what it looked like before...',
            stars: 5,
            directorio: 'manta'
        },
        {
            nombreR: 'Frank Calvo',
            coment: 'Super prompt to our reaching out for bids. Extremely professional',
            stars: 5,
            directorio: ''
        }

    ]





    return (
        <>
            {/* <div className='bg-white shadow-2xl rounded-xl w-[400px] h-[400px]'>

    </div> */}

            <div className='w-full md:w-4/5 h-auto mx-auto grid grid-cols-1 md:grid-cols-3 gap-5'>
                {rpdataReviews.map((item, index) => {

                    return (
                        <div
                            className='bg-white shadow-2xl rounded-xl p-6 '
                            key={index}
                        >
                            <div className='flex'>
                                <FaUserCircle fontSize={45} opacity={0.2} />
                                <div className='pl-5 pb-4'>
                                    <h5>{item.nombreR}</h5>
                                    <Starts score={item.stars} />
                                </div>
                            </div>
                            <p>{item.coment}</p>
                            <div>
                                {
                                    item.directorio === 'manta' ?
                                        <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
                                            width={40} height={40}
                                            viewBox="0 0 717 717">
                                            <defs>
                                                <style>{`
                                                .cls-1{
                                                    fill:#ff6b61;
                                                }
                                                `}</style>
                                            </defs>
                                            <title>manta_logo_freelogovectors.net</title>
                                            <path class="cls-1" d="M374.59,270A104.59,104.59,0,1,0,479.18,374.59,104.54,104.54,0,0,0,374.59,270M620.1,621.2a33.23,33.23,0,0,1-47.34,0L469.27,517.71c-67.16,45.14-157.44,37.43-216.89-20.92A171.27,171.27,0,0,1,494.59,254.58c58.35,58.35,66.06,149.73,20.92,216.89L619,575c14.31,12.11,14.31,33,1.1,46.24m8.81-499.83c-139.82-139.83-367.72-139.83-507.54,0s-139.83,367.72,0,507.54C190.73,698.27,281,732.4,371.28,733.5H703.77a29.6,29.6,0,0,0,29.73-29.73V371.28C732.4,281,697.17,189.63,628.91,121.37" transform="translate(-16.5 -16.5)" />
                                        </svg>
                                        :
                                        <img
                                            src='https://firebasestorage.googleapis.com/v0/b/clientesimages.appspot.com/o/directorios%2Fnetworx.png?alt=media&token=5da761fe-c2f6-4f2e-92f2-39c55d96e360'
                                            alt='nextworx icons'
                                            className='w-[50%]'
                                        />
                                }
                            </div>
                        </div>
                    )
                })}
            </div>
        </>
    )
}

export default CardReview